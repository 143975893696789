import { NapaDialogConfig } from '@shared/modules/dialog/models/dialog.models';

export const UNSAVED_CHANGES_DIALOG_CONFIG: NapaDialogConfig = {
  title: 'Are you sure you want to leave?',
  body: 'You have unsaved changes. Are you sure you want to leave this page?',
  hasPrimaryBtn: true,
  hasSecondaryBtn: true,
  closeOnButtonClick: true,
  secondaryBtnText: 'Cancel',
  primaryBtnText: 'Confirm',
};

export const CHECKED_INTAKE_DIALOG_CONFIG: NapaDialogConfig = {
  title: 'Intake form',
  body: 'Before we have you register, please click here <a href="https://form.jotform.com/240914043427047" target="_blank">website</a> to complete our intake paperwork',
  hasPrimaryBtn: false,
  hasSecondaryBtn: true,
  closeOnButtonClick: true,
  secondaryBtnText: 'Cancel',
};
